// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-approach-js": () => import("./../../../src/pages/approach.js" /* webpackChunkName: "component---src-pages-approach-js" */),
  "component---src-pages-become-a-carer-js": () => import("./../../../src/pages/become-a-carer.js" /* webpackChunkName: "component---src-pages-become-a-carer-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-condition-cancer-js": () => import("./../../../src/pages/condition/cancer.js" /* webpackChunkName: "component---src-pages-condition-cancer-js" */),
  "component---src-pages-condition-dementia-js": () => import("./../../../src/pages/condition/dementia.js" /* webpackChunkName: "component---src-pages-condition-dementia-js" */),
  "component---src-pages-condition-multiple-sclerosis-js": () => import("./../../../src/pages/condition/multiple-sclerosis.js" /* webpackChunkName: "component---src-pages-condition-multiple-sclerosis-js" */),
  "component---src-pages-condition-parkinsons-js": () => import("./../../../src/pages/condition/parkinsons.js" /* webpackChunkName: "component---src-pages-condition-parkinsons-js" */),
  "component---src-pages-condition-stroke-js": () => import("./../../../src/pages/condition/stroke.js" /* webpackChunkName: "component---src-pages-condition-stroke-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-cta-js": () => import("./../../../src/pages/cta.js" /* webpackChunkName: "component---src-pages-cta-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-liveincare-js": () => import("./../../../src/pages/liveincare.js" /* webpackChunkName: "component---src-pages-liveincare-js" */),
  "component---src-pages-our-carers-js": () => import("./../../../src/pages/our-carers.js" /* webpackChunkName: "component---src-pages-our-carers-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-request-a-brochure-js": () => import("./../../../src/pages/request-a-brochure.js" /* webpackChunkName: "component---src-pages-request-a-brochure-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-location-js": () => import("./../../../src/templates/location.js" /* webpackChunkName: "component---src-templates-location-js" */)
}

